import makeValidationSchema from './TradePartyMakeValidationSchema'
import { components, useTranslation,constants ,useServices} from 'cng-web-lib'
import React,{useState, useEffect} from 'react'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import { Divider, Grid, Typography,InputAdornment,IconButton,Box, useTheme } from '@material-ui/core'
import { NaStateCodeAutoCompleteField } from '../../../components/na'
import { useFormContext,useWatch } from 'react-hook-form'
import Alert from '../../../components/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NaNbciPartyMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import _ from 'lodash'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import  PartyMasterApiUrls  from '../../../apiUrls/NBCIUSCAPartyMasterApiUrls'
import { fetchUser } from 'src/views/userprofile/UserProfileService.js'


const {
  form: {
    field: {
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField,
      CngCountryAutocompleteField
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL,IN }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId:"",
  partyName: "",
  partyType: "",
  partyIdentifierCode: "",
  partyIdentifierNumber: "",
  partyScacCode: "",
  partyAddress: "",
  partyCity: "",
  partyState: "",
  partyCountry: "",
  partyPostalCode: "",
  partyLocalContactName: "",
  partyTelephoneNumber: "",
  partyFax: "",
  dba: "",
  fcc740Id: "",
  fdaRegNum: "",
  partyEmail: "",
  saveFlag: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, getValues, reset, trigger } = useFormContext();
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { watch } = useFormContext()

  const saveFlag = watch('saveFlag');
  const partyType = watch('partyType')
  const partyIdentifierCode = watch('partyIdentifierCode')
  const tradeParty = watch('tradeParty')
  const [templateDialog, setTemplateDialog] = useState(false)
  const [lookups, setLookups] = useState(null)
  const [inputPartyIDField, setInputPartyIDField] = useState();
  const partyCountry = watch('partyCountry')
  const partyState = watch('partyState')

  useEffect(() => {
    if(partyIdentifierCode==null || partyIdentifierCode == undefined || partyIdentifierCode == ""){
      if (partyType === "LW") {
        setValue('partyIdentifierCode', "UPSC")
      }
      if (partyType === "EX") {
        setValue('partyIdentifierCode', "MID")
      }
      if (partyType === "UC" || partyType === "41" || partyType === "SO") {
        setValue('partyIdentifierCode', "IRS")
      }
      if (partyType === "ZZ" || partyType === "MF" || partyType === "CS") {
        setValue('partyIdentifierCode', "FDA")
      }
      if (partyType === "BT" || partyType === "MP" || partyType === "BR" || partyType === "NP" || 
        partyType === "SP" || partyType === "CA" || partyType === "CE" || partyType === "IR" ||
        partyType === "GE") {
        setValue('partyIdentifierCode', null)
      }
    }
  }, [partyType])
  
  //UPSC
  
  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const { securedSendRequest } = useServices();
  const [user, setUser] = useState([]);

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    var upsClID="";
    if (user && Object.keys(user).length > 0) {
      console.log("upsScsClientId"+ JSON.stringify(user.upsSacClientId));
      if (user.upsSacClientId) {
        user.upsSacClientId.forEach((e) => {
          //setValue('upsScsClientId', e.upsSacClientId, { shouldValidate: true });
          upsClID=e.upsSacClientId;
        })
      }
      if(upsClID){
        if(partyType=="LW"){
          if(upsClID.indexOf("~") !== -1){
            if(upsClID.substring(0,upsClID.indexOf("~")).length>0){
              upsClID= upsClID.substring(0,upsClID.indexOf("~"));
            }else{
              upsClID= upsClID.substring(upsClID.indexOf("~")+1,upsClID.length);
            }
          }
          setValue("partyIdentifierNumber",upsClID.trim()) 
          setValue("partyIdentifierCode",'UPSC')
        }
      }
    }
  }, [user,tradeParty,partyType])

  const columns = [
    {
      field: 'inputPartyID',
      title: translatedTextsObject.partyId
    },
    {
      field: 'partyType',
      title: translatedTextsObject.partyType,
      render: data => 
      
      <Typography component='div' variant='inherit' style={{ marginBottom: 4 }}>
      {getLookupValue('partyType', data.partyType)}
       </Typography>
    },
    {
      field: 'partyName',
      title: translatedTextsObject.partyName,
    },
    {
      title: translatedTextsObject.partyAddress,
      render: data => [data.address1, data.address2, data.address3].join(' ')
    }

  ]

  const sortOptions = [
    {
      label: translatedTextsObject.inputPartyID,
      value: 'inputPartyID'
    },
    {
      label: translatedTextsObject.partyType,
      value: 'partyType'
    },
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        inputPartyID,
        partyType,
        partyName,
        address1,
        nameDBA,
        scac,
        cityName,
        stateCode,
        postalCode,
        countryCode,
        faxNo,
        contactPerson,
        telNo,
        email,
        partyIdentifierSection
      } = template

      const partyIdentifierCode = partyIdentifierSection && partyIdentifierSection.length > 0 ? partyIdentifierSection[0].partyIdentifierCode : "";
      const partyIdentifierNumber = partyIdentifierSection && partyIdentifierSection.length > 0 ? partyIdentifierSection[0].partyIdentifierNumber : "";

      setInputPartyIDField(inputPartyID)
      setValue('partyId', inputPartyID, { shouldDirty: true })
      setValue('partyType', partyType, { shouldDirty: true })
      setValue('partyName', partyName || '', { shouldDirty: true })
      setValue('dba', nameDBA || '', { shouldDirty: true })
      setValue('partyScacCode', scac || '', { shouldDirty: true })
      setValue('partyAddress', address1 || '', { shouldDirty: true })
      setValue('partyCity', cityName || '', { shouldDirty: true })
      setValue('partyState', stateCode || '', { shouldDirty: true })
      setValue('partyCountry', countryCode || '', { shouldDirty: true })
      setValue('partyPostalCode', postalCode || '', { shouldDirty: true })
      setValue('partyIdentifierCode', partyIdentifierCode, { shouldDirty: true })
      setValue('partyIdentifierNumber',partyIdentifierNumber, { shouldDirty: true })
      setValue('partyLocalContactName', contactPerson || '', { shouldDirty: true })
      setValue('partyTelephoneNumber', telNo || '', { shouldDirty: true })
      setValue('partyFax', faxNo || '', { shouldDirty: true })
      setValue('partyEmail',email || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }

  function makeTranslatedTextsObject() {
    const partyId = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_ID
    )
    const partyName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_NAME
    )
    const partyType = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_TYPE
    )
    const partyIdentifierCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_IDENTIFIER_CODE
    )
    const partyIdentifierNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_IDENTIFIER_NUMBER
    )
    const partyScacCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_SCAC_CODE
    )
    const partyAddress = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_ADDRESS
    )
    const partyCity = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_CITY
    )
    const partyState = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_STATE
    )
    const partyCountry = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_COUNTRY
    )
    const partyPostalCode = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_POSTAL_CODE
    )
    const partyLocalContactName = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_LOCAL_CONTACT_NAME
    )
    const partyTelephoneNumber = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_TELEPHONE_NUMBER
    )
    const partyFax = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_FAX
    )
    const dba = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.DBA
    )
    const partyEmail = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.PARTY_EMAIL
    )
    const note = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.NOTE_LABEL
    )
    const noteLabel2 = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.TradeParty.NOTE_PARTY2
    )
    const saveFlag = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SAVE_FLAG
    )


    return {
      partyId,
      partyType,
      dba,
      partyName,
      partyScacCode,
      partyIdentifierCode,
      partyIdentifierNumber,
      partyAddress,
      partyCity,
      partyCountry,
      partyState,
      partyPostalCode,
      partyLocalContactName,
      partyTelephoneNumber,
      partyFax,
      partyEmail,
      noteLabel2,
      note,
      saveFlag
    }
  }

	return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }}>
          Party Information
        </Typography>
        <Typography color='textSecondary' variant='caption'>
          <Typography variant='inherit' color='error'>
            *
          </Typography>
          Mandatory fields
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap?.saveFlag}>
                  <CngCheckboxField
                    label={
                      <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {translatedTextsObject.saveFlag}
                      </Typography>
                    }
                    name='saveFlag'
                    disabled={disabled}
                    onChange={(e) => {
                      setValue('saveFlag', e.target.checked)

                      if (!e.target.checked && undefined === inputPartyIDField) {
                        setValue("partyId", "")
                      }
                    }}
                    size='small'
                    style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                  />
           </CngGridItem>
           <CngGridItem xs={12} sm shouldHide={shouldHideMap?.partyId}>
                  <CngTextField
                    required
                    name="partyId" 
                    inputProps={{
                      style: { textTransform: 'uppercase' },
                      maxLength: 35
                    }}
                    placeholder={translatedTextsObject.partyId}
                    disabled={disabled || !saveFlag}
                    onChange={(e) => {
                      setValue("partyId", e.target.value.toUpperCase(), { shouldValidate: true })
                    }}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm='auto'>
                  <Divider orientation='vertical' />
                </CngGridItem>
                <CngGridItem xs={12} sm={4}>
                  <NaNbciPartyMasterAutoCompleteField
                    name="partyDropDown"
                    label='Auto-fill using Party Master'
                    disabled={disabled}
                    onChange={(_, options) => handleApplyTemplate(options.data)}
                    size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      },
                      //{ field: 'partyType', operator: IN, value: ['LW', 'EX', 'BT', '41', 'MF', 'ST', 'SO','MP','CS','UC','BR','ZZ','NP','SP','CA','IR','CE','GE','CS'] },
                      { field: 'usorcaMaster', operator: EQUAL, value: 'US' }
                    ]
                    }} 
                  />
                </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyType}>
            <CngCodeMasterAutocompleteField
              codeType='PARTY_TYPE_US_MASTER'
              name='partyType'
              key={partyType}
              label={translatedTextsObject.partyType}
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={8} shouldHide={shouldHideMap.dba}>
            <CngTextField
              name='dba'
              label={translatedTextsObject.dba}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={8} shouldHide={shouldHideMap.partyName}>
            <CngTextField
              name='partyName'
              label={translatedTextsObject.partyName}
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyScacCode}>
            <CngTextField
              name='partyScacCode'
              label={translatedTextsObject.partyScacCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyIdentifierCode}>
            <CngCodeMasterAutocompleteField
              name='partyIdentifierCode'
              key={partyIdentifierCode}
              label={translatedTextsObject.partyIdentifierCode}
                disabled={partyType === "LW"}
              codeType='PARTY_MASTER_IDENTIFIER_CODE'
                required={partyType == "LW" || partyType === "EX" || partyType === "SO"}
              size='small'
            />
          </CngGridItem>
            {/* <CngGridItem xs={12} sm={6} shouldHide={partyType !== "LW"}>
            <CngCodeMasterAutocompleteField
              name='partyIdentifierCode'
              key={partyIdentifierCode}
              label={translatedTextsObject.partyIdentifierCode}
              disabled={true}
              codeType='PARTY_MASTER_IDENTIFIER_CODE'
			  isRequired              
			  size='small'
            />
          </CngGridItem> */}
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyIdentifierNumber}>
            <CngTextField
              name='partyIdentifierNumber'
              label={translatedTextsObject.partyIdentifierNumber}
                disabled={partyType === "LW"}
                required={partyType == "LW" || partyType == "EX" || partyType == "SO"}
              size='small'
            />
          </CngGridItem>
            {/* <CngGridItem xs={12} sm={6} shouldHide={partyType !== "LW"}>
            <CngTextField
              name='partyIdentifierNumber'
              label={translatedTextsObject.partyIdentifierNumber}
              disabled={true}
              isRequired
              size='small'
            />
          </CngGridItem> */}
          <CngGridItem xs={12} shouldHide={shouldHideMap.partyAddress}>
            <CngTextField
              name='partyAddress'
              label={translatedTextsObject.partyAddress}
              disabled={disabled}
              isRequired
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyCity}>
            <CngTextField
              name='partyCity'
              label={translatedTextsObject.partyCity}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyCountry}>
            <CngCountryAutocompleteField
              name='partyCountry'
              key={partyCountry}
              label={translatedTextsObject.partyCountry}
              disabled={disabled}
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyState}>
            <NaStateCodeAutoCompleteField
              name='partyState'
              label={translatedTextsObject.partyState}
              disabled={disabled}
              size='small'
              countryCode={partyCountry}
              key={partyCountry + partyState}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyPostalCode}>
            <CngTextField
              name='partyPostalCode'
              label={translatedTextsObject.partyPostalCode}
              disabled={disabled}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs='auto'>
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              Local Contact Information
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} shouldHide={shouldHideMap.partyLocalContactName}>
              <CngTextField
                name='partyLocalContactName'
                label={translatedTextsObject.partyLocalContactName}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyTelephoneNumber}>
              <CngTextField
                name='partyTelephoneNumber'
                label={translatedTextsObject.partyTelephoneNumber}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyFax}>
              <CngTextField
                name='partyFax'
                label={translatedTextsObject.partyFax}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={4} shouldHide={shouldHideMap.partyEmail}>
              <CngTextField
                name='partyEmail'
                label={translatedTextsObject.partyEmail}
                disabled={disabled}
                size='small'
              />
            </CngGridItem>
            <Grid item xs={12}>
                <Alert
                  severity='info'
                  icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
                >
                  <strong>{translatedTextsObject.note}:</strong>
                  &nbsp;{translatedTextsObject.noteLabel2}
                </Alert>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <TemplateSelectDialog
    columns={columns}
    defaultSorts={[{ field: 'partyId', direction: 'ASC' }]}
    fetch={{ url: PartyMasterApiUrls.GET }}
    fetchFilters={[
      { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() },
      //{ field: 'partyType', operator: IN, value: ['LW', 'EX', 'BT', '41', 'MF', 'ST', 'SO','MP','CS','UC','BR','ZZ','NP','SP','CA','IR','CE','GE','CS'] },
      { field: 'usorcaMaster', operator: EQUAL, value: ['US'] }
    ]}
    onApplyTemplate={handleApplyTemplate}
    onClose={() => setTemplateDialog(false)}
    open={templateDialog}
        search={{ field: 'inputValue', operator: LIKE }} //custom field name for BE to custom search
    sortOptions={sortOptions}
    title='Template'
  />
    </>
	)
}

const TradePartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TradePartyFormProperties
